import axios from "axios";
import storage from "./storage";
import { BASE_URL } from "./constants";
import { useContext, useEffect } from "react";
import { UserGlobalContextMemorySpace } from "../contexts/user/userGlobalContext";

const pendingRequests = {}; // Registro de solicitudes pendientes

export const fetch = async (
  endpoint,
  method,
  data,
  options = { ContentType: "application/json" }
) => {
  const token = await storage.get("token");
  const URL = `${process.env.REACT_APP_GATEWAY_QA}/${endpoint}`;
  const headers = {
    "Content-Type": options.ContentType,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios({
    url: URL,
    method,
    headers,
    data,
  });
};

export const fetchStrapi = async (
  endpoint,
  method,
  data,
  options = { ContentType: "application/json" }
) => {
  const token = await storage.get("token");
  const URL = `${process.env.REACT_APP_STRAPI_QA}/${endpoint}`;
  const headers = {
    "Content-Type": options.ContentType,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios({
    url: URL,
    method,
    headers,
    data,
  });
};

export const fetchStrapiPSHB = async (
  endpoint,
  method,
  data,
  options = { ContentType: "application/json" }
) => {
  const token = await storage.get("token");
  const URL = `${process.env.REACT_APP_STRAPI_PSHB}/${endpoint}`;
  const headers = {
    "Content-Type": options.ContentType,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios({
    url: URL,
    method,
    headers,
    data,
  });
};

export const fetchExternal = async (
  URL,
  method,
  data,
  options = { ContentType: "application/json" }
) => {
  const token = await storage.get("token");
  const headers = {
    "Content-Type": options.ContentType,
  };

  return axios({
    url: URL,
    method,
    headers,
    data,
  });
};

// const auth = async () => {
//   try {

//     var myHeaders = new Headers();

//     var myInit = {
//       method: 'POST',
//       headers: myHeaders,
//       mode: 'cors',
//       cache: 'default'
//     };

//     var myRequest = new Request(process.env.REACT_APP_STRAPI_QA + '/external-auth/auth', myInit);

//     fetch(myRequest)
//       .then(function (response) {
//         return response.json();
//       })
//       .then(function (data) {
//        console.log(data)
//       });

//   } catch (err) {
//     console.log(err)
//   }
// };

const auth = async () => {
  try {
    return axios({
      url: process.env.REACT_APP_STRAPI_QA + "/external-auth/auth",
      method: "POST",
    });
  } catch (err) {
    console.log(err);
  }
};

function sendRequest(req,axiosApiInstance) {
  const requestId = JSON.stringify(req); // Genera una identificación única para la solicitud
  if (!pendingRequests[requestId]) { // Verifica si la solicitud ya se ha realizado
    pendingRequests[requestId] = axiosApiInstance(req).finally(() => {
      delete pendingRequests[requestId]; // Elimina la solicitud del registro una vez que se completa
    });
  }
  return pendingRequests[requestId];
}

export const myAxios = async (req) => {
  // let res = await auth();
  // console.log( res.data.token)
  // req.headers.Authorization = "Bearer " + res.data.token;

  const axiosApiInstance = axios.create();
  axiosApiInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {      
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        console.log("err", error.response);
        originalRequest._retry = true;
        const res = await auth();
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
        originalRequest.headers.Authorization = "Bearer " + res.data.token;
        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  return sendRequest(req,axiosApiInstance);
};

// export const fetchLogs = async (req) => {
//   const {
//     idLogType,
//     idType,
//     idCustomer = "",
//     description,
//     code = "",
//     doctor = "",
//   } = req;

//   const modifiedDescription = `HB-${description}`;

//   axios({
//     url:
//       process.env.REACT_APP_STRAPI_QA +
//       process.env.REACT_APP_STRAPI_ENDPOINT_LOGS,
//     method: "POST",
//     data: {
//       idLogType,
//       idType,
//       idCustomer,
//       description: modifiedDescription,
//       code,
//       doctor,
//     },
//   });
// };

export const fetchLogs = async (req) => {
  console.log("REQ",req);
  
  try {
    const {
      idLogType,
      idType,
      idCustomer = "",
      description,
      code = "",
      doctor = "",
    } = req;

    const modifiedDescription = `HB-${description}`;    
    const url = `${process.env.REACT_APP_GATEWAY_QA}/patient/Api/v1/LogData/WriteLoggingStatic`;

    axios({
      url,
      method: "POST",
      headers: {
        "Content-Type": "application/json",        
      },
      data: {
        idLogType,
        idType,
        idCustomer,
        description: modifiedDescription,
        code,
        doctor,
      },
    });
  } catch (error) {}
};