import { FiEdit2, FiX, FiCheck } from "react-icons/fi";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";
import React, { useContext } from "react";
import CustomSelectMiCobertura from "../../customSelectMiCobertura";
import { CUSTOM_SELECT_PROFILE } from "../../customSelect/constants";
import { useFormik } from "formik";
import { validateModel } from "./validationSchema";
import { myAxios } from "../../../../utils/fetch";
import "./mi-cobertura.scss";

export const MiCoberturaComponent = ({
  activo = false,
  editando = false,
  guardando,
  onEditando = () => null,
  confirmar = () => null,
  cancelar = () => null,
}) => {
  const { setOriginalUser, originalUser, sitePSHB, userPSHB, user } =
    useContext(UserGlobalContextMemorySpace);
  const [temporalUser, setTemporalUser] = React.useState(
    JSON.parse(JSON.stringify(user))
  );
  const [isDisableSend, setIsDisableSend] = React.useState(true);

  const [claseCampo, setClaseCampo] = React.useState("");
  const [socialSecurities, setSocialSecurities] = React.useState(null);
  const [plans, setPlans] = React.useState(null);
  const [socialsecurityIdGlobal, setSocialSecurityIdGlobal] =
    React.useState(null);
  const [socialsecurityGlobal, setSocialSecurityGlobal] = React.useState({
    id: null,
    name: null,
  });
  const [plansGlobal, setPlansGlobal] = React.useState(null);

  const [isValid, setIsValid] = React.useState(true);
  const [isPSHB, setIsPSHB] = React.useState(
    temporalUser.user.data.Customers.socialSecurity == "40080/000"
  );

  const [socialSecurityTextGlobal, setSocialSecurityTextGlobal] =
    React.useState();

  const [menuOpen, setMenuOpen] = React.useState(false);

  const [menuOpenPlan, setMenuOpenPlan] = React.useState(false);

  const [plansTextGlobal, setPlansTextGlobal] = React.useState();

  const [securityNumber, setSecurityNumber] = React.useState(
    temporalUser.user.data.Customers.socialSecurityNumber
  );

  const initialValues = {
    phone: temporalUser.user.data.Customers.phone,
    mobile: temporalUser.user.data.Customers.mobile,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateModel,
  });

  React.useEffect(() => {
    setIsDisableSend(true);
    if (editando) setClaseCampo("campo");
    else setClaseCampo("campo");
  }, [editando]);

  React.useEffect(() => {
    if (!temporalUser || !originalUser) return;

    const usr = originalUser?.user?.data?.Customers;
    const tmp = temporalUser?.user?.data?.Customers;

    if (
      usr.socialSecurity != tmp.socialSecurity ||
      usr.socialSecurityNumber != tmp.socialSecurityNumber ||
      usr.socialSecurityPlan != tmp.socialSecurityPlan
    ) {
      setIsDisableSend(false);
    } else {
      setIsDisableSend(true);
    }
  }, [temporalUser, originalUser]);

  React.useEffect(() => {
    temporalUser.user.data.Customers.socialSecurityNumber =
      temporalUser.user.data.Customers.socialSecurityNumber;
    if (socialSecurities == null) {
      if (sessionStorage.getItem("socialSecurities") !== null) {
        const socialSecuritiesList = JSON.parse(
          sessionStorage.getItem("socialSecurities")
        );
        setSocialSecurities(socialSecuritiesList);
      } else {
        myAxios({
          url:
            process.env.REACT_APP_GATEWAY_QA +
            "/customers/api/v1/user/getSocialSecurities",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            USERTOKEN: user.user.data.Token,
            Authorization: `Bearer ${user.user.data.appToken}`,
          },
        })
          .then((response) => {
            const socialSecuritiesList =
              response.data.data.SocialSecurities.map((socialSecurity) => {
                return {
                  id: socialSecurity.codigo,
                  name: socialSecurity.descripcion,
                };
              });
            setSocialSecurities(socialSecuritiesList);
            sessionStorage.setItem(
              "socialSecurities",
              JSON.stringify(socialSecuritiesList)
            );
          })
          .catch((error) => {});
      }
    }
    //
    if (isPSHB) {
      //Primer llamado API Verificar cobertura
      myAxios({
        url: `${process.env.REACT_APP_GATEWAY_QA}${
          process.env.REACT_APP_GATEWAY_ENDPOINT_VALIDATEPSHBPLAN
        }?credential=${encodeURIComponent(
          temporalUser.user.data.Customers.socialSecurityNumber
        )}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          USERTOKEN: user.user.data.Token,
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
      }).then((resp) => {
        if (resp.data != null) {
          if (
            resp.data.data.Afiliado == "ACTIVO" &&
            resp.data.data.documento == user.user.data.Customers.dni
          ) {
            seleccionarPlan(resp.data.data.codigoplan.trim());
          } else {
            seleccionarPlan("");
          }
        }
      });
    }
  }, []);

  React.useEffect(() => {
    seleccionarSocialSecurity(temporalUser.user.data.Customers.socialSecurity);
  }, [socialSecurities]);

  React.useEffect(() => {
    //setPlans(null);
    consultarPlan(socialsecurityIdGlobal).then(() => {
      if (socialsecurityIdGlobal == "40080/000") {
        setIsPSHB(true);
      } else {
        setIsPSHB(false);
      }
    });
  }, [socialsecurityIdGlobal]);

  React.useEffect(() => {
    if (isPSHB) {
      //Primer llamado API Verificar cobertura
      myAxios({
        url: `${process.env.REACT_APP_GATEWAY_QA}${
          process.env.REACT_APP_GATEWAY_ENDPOINT_VALIDATEPSHBPLAN
        }?credential=${encodeURIComponent(
          temporalUser.user.data.Customers.socialSecurityNumber
        )}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          USERTOKEN: user.user.data.Token,
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
      }).then((resp) => {
        if (resp.data != null) {
          if (
            resp.data.data.Afiliado == "ACTIVO" &&
            resp.data.data.documento == user.user.data.Customers.dni
          ) {
            seleccionarPlan(resp.data.data.codigoplan.trim());
          } else {
            seleccionarPlan("");
          }
        }
      });
    }
  }, [securityNumber]);

  React.useEffect(() => {
    if (plans) {
      seleccionarPlan(temporalUser.user.data.Customers.socialSecurityPlan);
    }
  }, [plans]);

  const consultarPlan = async (socialSecurityId) => {
    if (!socialSecurityId) return;

    let response = await myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/user/GetSocialSecurityPlan?socialSecurity=" +
        encodeURIComponent(socialSecurityId),
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    });
    if (response != null && response.status == 200) {
      let socialSecuritiesPlansList = response.data.data.SocialSecurityPlan.map(
        (socialSecurityPlan) => {
          return {
            id: socialSecurityPlan.codigo,
            name: socialSecurityPlan.descripcion,
          };
        }
      );
      setPlans(socialSecuritiesPlansList);
    }
  };

  const seleccionarSocialSecurity = (socialSecurityId) => {
    const socialSelected = socialSecurities?.find((socialSecurity) => {
      return socialSecurity.id === socialSecurityId;
    });

    setSocialSecurityGlobal(socialSelected ?? { id: null, name: null });
    setSocialSecurityTextGlobal(socialSelected ?? { id: null, name: null });
    setSocialSecurityIdGlobal(socialSelected?.id);
  };

  const seleccionarPlan = (planId) => {
    const planSelected = plans?.find((socialSecurityPlan) => {
      return socialSecurityPlan.id === planId;
    });

    if (planSelected) {
      setPlansGlobal({ ...planSelected });
      setPlansTextGlobal({ ...planSelected });
    } else {
      if (!plans) return;
      setPlansGlobal({ ...plans[0] });
      setPlansTextGlobal({ ...plans[0] });
    }
    temporalUser.user.data.Customers.socialSecurityPlan = planId;
    setTemporalUser(JSON.parse(JSON.stringify(temporalUser)));
  };

  const changeValue = (e) => {
    temporalUser.user.data.Customers = {
      ...temporalUser.user.data.Customers,
      [e.target.name]: e.target.value,
    };
    setTemporalUser(JSON.parse(JSON.stringify(temporalUser)));
    formik.setFieldValue([e.target.name], e.target.value);

    if (e.target.name == "socialSecurityNumber" && isPSHB) {
      //Tercer llamado API Verificar cobertura
      setSecurityNumber(e.target.value);

      myAxios({
        url: `${process.env.REACT_APP_GATEWAY_QA}${
          process.env.REACT_APP_GATEWAY_ENDPOINT_VALIDATEPSHBPLAN
        }?credential=${encodeURIComponent(e.target.value.replaceAll(" ", ""))}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          USERTOKEN: user.user.data.Token,
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
      }).then((resp) => {
        if (resp.data != null) {
          if (
            resp.data.data.Afiliado == "ACTIVO" &&
            resp.data.data.documento == user.user.data.Customers.dni
          ) {
            seleccionarPlan(resp.data.data.codigoplan.trim());
          } else {
            seleccionarPlan("");
          }
        }
      });
    }
  };

  const onChange = (id, name) => {
    if (name === "socialSecurity") {
      temporalUser.user.data.Customers.socialSecurityPlan = "";
    }

    temporalUser.user.data.Customers = {
      ...temporalUser.user.data.Customers,
      [name]: id,
    };

    setTemporalUser(JSON.parse(JSON.stringify(temporalUser)));

    if (name === "socialSecurity") {
      seleccionarSocialSecurity(id);
      setSocialSecurityIdGlobal(id);
      if (id == "40080/000") {
        //Segundo llamado API Verificar cobertura
        setIsPSHB(true);
        myAxios({
          url: `${process.env.REACT_APP_GATEWAY_QA}${
            process.env.REACT_APP_GATEWAY_ENDPOINT_VALIDATEPSHBPLAN
          }?credential=${encodeURIComponent(
            securityNumber.replaceAll(" ", "")
          )}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            USERTOKEN: user.user.data.Token,
            Authorization: `Bearer ${user.user.data.appToken}`,
          },
        }).then((resp) => {
          if (resp.data != null) {
            if (
              resp.data.data.Afiliado == "ACTIVO" &&
              resp.data.data.documento == user.user.data.Customers.dni
            ) {
              seleccionarPlan(resp.data.data.codigoplan.trim());
            } else {
              seleccionarPlan("");
            }
          }
        });
      }
    } else if (name === "socialSecurityPlan") {
      seleccionarPlan(id);
    }
  };

  const emptyPlans = () => {
    setPlansTextGlobal("");
    setSocialSecurityTextGlobal("");
    temporalUser.user.data.Customers.socialSecurityNumber = "";
    setMenuOpen(true);
  };

  const emptyPlansPlan = () => {
    setPlansTextGlobal("");
    setMenuOpenPlan(true);
  };

  const onInputChange = (cualEs) => {
    //Vuelve a poner cobertura cuando no se selecciona nada
    const socialSelected = socialSecurities?.find((socialSecurity) => {
      return (
        socialSecurity.id === temporalUser.user.data.Customers.socialSecurity
      );
    });

    setSocialSecurityTextGlobal(socialSelected ?? { id: null, name: null });

    if (cualEs != "socialSecurity") {
      //Vuelve a poner plan cuando no se selecciona nada
      const planSelected = plans?.find((socialSecurityPlan) => {
        return (
          socialSecurityPlan.id ===
          temporalUser.user.data.Customers.socialSecurityPlan
        );
      });
      setPlansTextGlobal(planSelected);
    }

    //Vuelve a poner numero afiliado cuando no se selecciona nada
    temporalUser.user.data.Customers.socialSecurityNumber = securityNumber;

    setMenuOpen(false);
  };

  const onInputChangePlan = () => {
    const planSelected = plans?.find((socialSecurityPlan) => {
      return (
        socialSecurityPlan.id ===
        temporalUser.user.data.Customers.socialSecurityPlan
      );
    });
    setPlansTextGlobal(planSelected);

    setMenuOpenPlan(false);
  };

  const handleConfirmar = (values) => {
    setOriginalUser(values);
    setIsDisableSend(true);
    confirmar(values);
  };

  return (
    <>
      {/* Componente para dispositivos móviles (oculto en pantallas medianas y grandes) */}
      <div class="d-block d-md-none w-100">
        {/* INICIO MOBILE   */}
        <div className={activo ? "" : "d-none"}>
          <div
            className={activo ? "formActivo" : "formInactivo"}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: "10px",
              paddingTop: "30px",
            }}
          >
            {/* CAMPOS DE FORMULARIOS */}
            <div style={{ display: "flex" }}>
              <span className="perfil-etiqueta me-1">Cobertura:</span>
              {userPSHB ? (
                <span
                  className="perfil-valor"
                  style={{ width: "100%", marginTop: "-5px" }}
                >
                  PLAN DE SALUD DEL HOSPITAL BRITANICO
                </span>
              ) : (
                <span
                  className="perfil-valor"
                  style={{ width: "100%", marginTop: "-5px" }}
                >
                  {user.user.data.Customers.pshb && sitePSHB ? (
                    socialsecurityGlobal.name
                  ) : !editando ? (
                    socialsecurityGlobal.name
                  ) : (
                    <div
                      style={{ width: "100%", marginTop: "-5px" }}
                      className={
                        "perfil " + (userPSHB ? "perfil-PSHB" : "perfil-HB")
                      }
                    >
                      <div
                        style={{ position: "relative", fontSize: "14px" }}
                        onTouchStart={() => {
                          setMenuOpen(true);
                          emptyPlans();
                        }}
                      >
                        <CustomSelectMiCobertura
                          key={menuOpen}
                          defaultText={socialSecurityTextGlobal}
                          optionsList={
                            socialSecurities ?? [{ id: "", name: "" }]
                          }
                          type={CUSTOM_SELECT_PROFILE}
                          width="100%"
                          onChange={onChange}
                          name="socialSecurity"
                          menuOpen={menuOpen}
                          onInputChange={onInputChange}
                          onMenuOpen={() => {
                            emptyPlans();
                          }}
                        />
                      </div>
                    </div>
                  )}
                </span>
              )}
            </div>
            <div style={{ display: "flex" }}>
              <span
                className="perfil-etiqueta me-1"
                style={{ marginTop: "9px", marginBottom: "10px" }}
              >
                Plan:
              </span>
              {userPSHB ? (
                <span
                  className="perfil-valor"
                  style={{ width: "100%", marginTop: "9px" }}
                >
                  {user?.user?.data?.Customers?.plan
                    ? user?.user?.data?.Customers?.plan
                    : ""}
                </span>
              ) : (
                <>
                  {plans != null ? (
                    !editando ? (
                      user.user.data.Customers.socialSecurity === "40080/000" &&
                      user?.user?.data?.Customers?.plan ? (
                        <span style={{ marginTop: "9px" }}>
                          {user?.user?.data?.Customers?.plan}
                        </span>
                      ) : (
                        <span style={{ marginTop: "9px" }}>
                          {plansGlobal?.name}
                        </span>
                      )
                    ) : (
                      <div
                        style={{ width: "100%" }}
                        className={
                          "perfil " + (userPSHB ? "perfil-PSHB" : "perfil-HB")
                        }
                      >
                        <div
                          style={{ position: "relative", fontSize: "14px" }}
                          onTouchStart={() => {
                            if (isPSHB) return;
                            setMenuOpenPlan(true);
                          }}
                        >
                          <CustomSelectMiCobertura
                            key={Math.random()}
                            defaultText={
                              plansTextGlobal ?? [{ id: "", name: "" }]
                            }
                            optionsList={plans}
                            type={CUSTOM_SELECT_PROFILE}
                            width="100%"
                            onChange={onChange}
                            name="socialSecurityPlan"
                            menuOpen={menuOpenPlan}
                            onInputChangePlan={onInputChangePlan}
                            onMenuOpen={() => {
                              emptyPlansPlan();
                            }}
                            disabled={isPSHB}
                          />
                        </div>
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </>
                // </span>
              )}
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "100px 1fr" }}>
              <span className="perfil-etiqueta me-1">N° afiliado:</span>
              {userPSHB ? (
                <span className="perfil-valor">
                  {user?.user?.data?.Customers?.credencial
                    ? user?.user?.data?.Customers?.credencial
                    : ""}
                </span>
              ) : (
                <span className="perfil-valor">
                  {userPSHB ? (
                    temporalUser.user.data.Customers.socialSecurityNumber
                  ) : !editando ? (
                    temporalUser.user.data.Customers.socialSecurityNumber
                  ) : (
                    <input
                      type="text"
                      className="underlined-input"
                      name="socialSecurityNumber"
                      maxLength={20}
                      style={{
                        width: "100%",
                        marginLeft: "0px",
                        marginTop: "-5px",
                      }}
                      value={
                        temporalUser.user.data.Customers.socialSecurityNumber
                      }
                      onChange={changeValue}
                    />
                  )}
                </span>
              )}
            </div>
            <div className="text-danger ms-2">
              {temporalUser.user.data.Customers.socialSecurity !== "PARTICULAR"
                ? formik.errors.socialSecurityNumber
                : null}
            </div>
            {/* BOTONES DE EDITAR, CANCELAR, CONFIRMAR */}
            <div className="contenedor-botones">
              {!editando ? (
                <button
                  className="botonEditar"
                  style={{ width: "100px" }}
                  onClick={() => (activo ? onEditando() : null)}
                >
                  <FiEdit2 /> Editar
                </button>
              ) : (
                <>
                  <button
                    className={`${
                      isDisableSend ? "botonEditar-disable" : "botonEditar"
                    } ${userPSHB ? "boton-PSHB" : "boton-HB"}`}
                    disabled={isDisableSend}
                    onClick={() =>
                      guardando ? null : handleConfirmar(temporalUser)
                    }
                  >
                    {guardando ? (
                      <>
                        <div
                          style={{
                            verticalAlign: "inherit",
                            marginLeft: "5px",
                            width: "0.7rem",
                            height: "0.7rem",
                            color: "rgb(93, 132, 170)",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                        <div
                          style={{
                            verticalAlign: "inherit",
                            marginLeft: "5px",
                            width: "0.7rem",
                            height: "0.7rem",
                            color: "rgb(93, 132, 170)",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                        <div
                          style={{
                            verticalAlign: "inherit",
                            marginLeft: "5px",
                            width: "0.7rem",
                            height: "0.7rem",
                            color: "rgb(93, 132, 170)",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                      </>
                    ) : (
                      <>
                        <FiCheck /> Confirmar
                      </>
                    )}
                  </button>
                  <button
                    className="botonCancelar"
                    onClick={() => cancelar(setTemporalUser)}
                  >
                    <FiX /> Cancelar
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        {/* FIN MOBILE */}
      </div>

      {/* Componente para pantallas medianas y grandes (oculto en dispositivos móviles) */}
      <div class="d-none d-md-block w-100">
        {/* INICIO DESKTOP */}
        <div className={activo ? "" : "d-none"}>
          <div className={activo ? "formActivo" : "formInactivo"}>
            {/* LINEA SUPERIOR */}
            <div
              className={
                activo
                  ? "lineaCabActiva"
                  : userPSHB
                  ? "lineaCabInactivaPSHB"
                  : "lineaCabInactiva"
              }
            />

            {/* BOTONES DE EDITAR, CANCELAR, CONFIRMAR */}

            {!userPSHB ? (
              <>
                <div className="botones">
                  {!editando && (
                    <button
                      className="botonEditar"
                      onClick={() => (activo ? onEditando() : null)}
                      style={{ paddingBottom: "2px" }}
                    >
                      <FiEdit2 /> Editar
                    </button>
                  )}
                  {editando && (
                    <>
                      <button
                        className={`${
                          isDisableSend ? "botonEditar-disable" : "botonEditar"
                        } ${userPSHB ? "boton-PSHB" : "boton-HB"}`}
                        disabled={isDisableSend}
                        onClick={
                          isValid && !guardando
                            ? () => handleConfirmar(temporalUser)
                            : null
                        }
                      >
                        {guardando ? (
                          <>
                            <div
                              style={{
                                verticalAlign: "inherit",
                                marginLeft: "5px",
                                width: "0.7rem",
                                height: "0.7rem",
                                color: "rgb(93, 132, 170)",
                              }}
                              className="spinner-grow text-muted"
                            ></div>
                            <div
                              style={{
                                verticalAlign: "inherit",
                                marginLeft: "5px",
                                width: "0.7rem",
                                height: "0.7rem",
                                color: "rgb(93, 132, 170)",
                              }}
                              className="spinner-grow text-muted"
                            ></div>
                            <div
                              style={{
                                verticalAlign: "inherit",
                                marginLeft: "5px",
                                width: "0.7rem",
                                height: "0.7rem",
                                color: "rgb(93, 132, 170)",
                              }}
                              className="spinner-grow text-muted"
                            ></div>
                          </>
                        ) : (
                          <>
                            <FiCheck /> Confirmar
                          </>
                        )}
                      </button>
                      <button
                        className={
                          "botonCancelar " +
                          (userPSHB ? "boton-PSHB" : "boton-HB")
                        }
                        onClick={() => cancelar(setTemporalUser)}
                      >
                        <FiX /> Cancelar
                      </button>
                    </>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            {/* CAMPOS DE FORMULARIOS */}
            <div
              className={user.user.data.Customers.pshb ? "campo" : claseCampo}
              style={{ display: "flex" }}
            >
              <span
                className="perfil-etiqueta"
                style={{ width: "100px", minWidth: "100px" }}
              >
                Cobertura:
              </span>
              {userPSHB ? (
                <span className="perfil-valor" style={{ width: "100%" }}>
                  PLAN DE SALUD DEL HOSPITAL BRITANICO
                </span>
              ) : (
                <span className="perfil-valor" style={{ width: "100%" }}>
                  {user.user.data.Customers.pshb && sitePSHB ? (
                    socialsecurityGlobal.name
                  ) : !editando ? (
                    socialsecurityGlobal.name
                  ) : (
                    <div
                      style={{ width: "100%", marginTop: "-5px" }}
                      className={
                        "perfil " + (userPSHB ? "perfil-PSHB" : "perfil-HB")
                      }
                    >
                      <div
                        style={{ position: "relative" }}
                        onTouchStart={() => {
                          setMenuOpen(true);
                          emptyPlans();
                        }}
                      >
                        <CustomSelectMiCobertura
                          key={menuOpen}
                          defaultText={socialSecurityTextGlobal}
                          optionsList={
                            socialSecurities ?? [{ id: "", name: "" }]
                          }
                          type={CUSTOM_SELECT_PROFILE}
                          width="100%"
                          onChange={onChange}
                          name="socialSecurity"
                          menuOpen={menuOpen}
                          onInputChange={onInputChange}
                          onMenuOpen={() => {
                            emptyPlans();
                          }}
                        />
                      </div>
                    </div>
                  )}
                </span>
              )}
            </div>

            <div className={activo ? "lineaActiva" : "lineaInactiva"} />
            <div className={claseCampo} style={{ display: "flex" }}>
              <span
                className="perfil-etiqueta"
                style={{ width: "100px", minWidth: "100px" }}
              >
                Plan:
              </span>
              {userPSHB ? (
                <span className="perfil-valor" style={{ width: "100%" }}>
                  {user?.user?.data?.Customers?.plan
                    ? user?.user?.data?.Customers?.plan
                    : ""}
                </span>
              ) : (
                <span className="perfil-valor" style={{ width: "100%" }}>
                  {plans != null ? (
                    !editando ? (
                      user.user.data.Customers.socialSecurity === "40080/000" &&
                      user?.user?.data?.Customers?.plan ? (
                        user?.user?.data?.Customers?.plan
                      ) : (
                        plansGlobal?.name
                      )
                    ) : (
                      <div
                        style={{ width: "100%", marginTop: "-5px" }}
                        className={
                          "perfil " + (userPSHB ? "perfil-PSHB" : "perfil-HB")
                        }
                      >
                        <div
                          style={{ position: "relative" }}
                          onTouchStart={() => {
                            setMenuOpenPlan(true);
                          }}
                        >
                          <CustomSelectMiCobertura
                            defaultText={plansGlobal?.name}
                            optionsList={plans}
                            type={CUSTOM_SELECT_PROFILE}
                            width="100%"
                            onChange={onChange}
                            name="socialSecurityPlan"
                          />

                          <CustomSelectMiCobertura
                            key={Math.random()}
                            defaultText={
                              plansTextGlobal ?? [{ id: "", name: "" }]
                            }
                            optionsList={plans}
                            type={CUSTOM_SELECT_PROFILE}
                            width="100%"
                            onChange={onChange}
                            name="socialSecurityPlan"
                            menuOpen={menuOpenPlan}
                            onInputChangePlan={onInputChangePlan}
                            onMenuOpen={() => {
                              emptyPlansPlan();
                              // setPlansTextGlobal("");
                            }}
                            disabled={isPSHB}
                          />
                        </div>
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </span>
              )}
            </div>

            <div className={activo ? "lineaActiva" : "lineaInactiva"} />
            <div
              className={userPSHB ? "campo" : claseCampo}
              style={{ display: "flex" }}
            >
              <span
                className="perfil-etiqueta"
                style={{ width: "100px", minWidth: "100px" }}
              >
                N° afiliado:
              </span>
              {userPSHB ? (
                <span className="perfil-valor">
                  {user?.user?.data?.Customers?.credencial
                    ? user?.user?.data?.Customers?.credencial
                    : ""}
                </span>
              ) : (
                <span className="perfil-valor">
                  {userPSHB ? (
                    temporalUser.user.data.Customers.socialSecurityNumber
                  ) : !editando ? (
                    temporalUser.user.data.Customers.socialSecurityNumber
                  ) : (
                    <input
                      type="text"
                      className="underlined-input"
                      name="socialSecurityNumber"
                      maxLength={20}
                      style={{ width: "100%", marginLeft: "0px" }}
                      value={
                        temporalUser.user.data.Customers.socialSecurityNumber
                      }
                      onChange={changeValue}
                    />
                  )}
                </span>
              )}
            </div>
            <div className="text-danger ms-2">
              {temporalUser.user.data.Customers.socialSecurity !== "PARTICULAR"
                ? formik.errors.socialSecurityNumber
                : null}
            </div>
          </div>
        </div>
        {/* FIN DESKTOP */}
      </div>
    </>
  );
};
