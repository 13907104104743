import React, { useContext, useState, useEffect } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import FaUserCircle from "../../../assets/img/hb/Perfil Menú.svg";
import FaProfessional from "../../../assets/img/hb/Profesionales y espe.  menú.svg";
import FaAtencion from "../../../assets/img/hb/Atención al paciente  menú.svg";
import FaPerfil from "../../../assets/img/hb/Perfil  menú.svg";
import FaInicio from "../../../assets/img/hb/Inicio  menú.svg";
import FaGrupoFamiliar from "../../../assets/img/hb/Grupo familiar  menú.svg";
import FaTurnos from "../../../assets/img/hb/Turnos  menú.svg";
import FaResultados from "../../../assets/img/hb/Resultados  menú.svg";
import FaCertificados from "../../../assets/img/hb/Certificado.svg";
import FaAyuda from "../../../assets/img/hb/Información  menú.svg";
import FaFacturacion from "../../../assets/img/hb/Facturacion.svg";
import FaReintegros from "../../../assets/img/hb/Reintegros.svg";
import FaCredencial from "../../../assets/img/hb/Credencial.svg";
import FaLogOut from "../../../assets/img/hb/Log out menú.svg";
import HospitalBritanico from "../../../assets/img/hb/Hospital Británico Home.svg";
import { NavLink } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Row, Col } from "react-bootstrap";
import "./style.scss";

const UserSidebar = (props) => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);
  const [showInfo, setShowInfo] = useState(false);
  const [isActivePerfil, setIsActivePerfil] = useState(false);
  const [isActiveReintegro, setIsActiveReintegro] = useState(false);
  const [isActiveFacturacion, setIsActiveFacturacion] = useState(false);

  const handleRedirect = () => {
    window.open("https://www.hospitalbritanico.org.ar/", "_blank");
  };

  useEffect(() => {
    //Mount:

    return () => {
      //Unmount
    };
  }, []);

  return (
    <>
      <aside
        className={
          userPSHB
            ? "asideWebPSHB d-flex justify-content-center scrollable-aside"
            : "asideWebHB d-flex justify-content-center scrollable-aside"
        }
        // style={{ height: `${altura}px` }}
        // style={{ maxHeight: props.height }}
      >
        <div className="d-flex flex-column" style={{ width: "100%" }}>
          <div
            className="d-flex align-items-center" // justify-content-center"
            style={{ backgroundColor: "#efefef", paddingBottom: "25px" }}
          >
            <img
              src={FaUserCircle}
              alt="..."
              style={{
                height: "40px",
                marginRight: "14px",
                marginLeft: "25px",
              }}
            />
            <div
              style={{
                color: "#224A8D",
                fontFamily: "Roboto-Bold",
                marginBottom: "0",
                marginRight: "10px",
                fontSize: "17px",
              }}
            >
              {user.user.data.Customers.firstName}{" "}
              {user.user.data.Customers.lastName}
            </div>
          </div>
          <div className="separadorTitulo" style={{ width: "100%" }} />
          <div
            className="d-flex flex-column justify-content-center"
            style={{ width: "100%", backgroundColor: "#efefef" }}
          >
            <NavLink
              className="navigate-button"
              to={"/"}
              activeclassname="active"
            >
              <div className="d-flex textoMenuLateral">
                <img src={FaInicio} className="iconosAtencion" alt="..." />{" "}
                <span>Inicio</span>
              </div>
            </NavLink>
            <div className="separadorItem" />
            <Row style={{ width: "100%", marginLeft: 0 }}>
              <NavLink
                className="navigate-button"
                to={"/perfil"}
                activeClassName="active"
                style={{ width: "78%" }}
              >
                <Col md={10}>
                  <div className="d-flex textoMenuLateral">
                    <div>
                      <img
                        src={FaPerfil}
                        className="iconosAtencion"
                        alt="..."
                      />{" "}
                      <span>Perfil</span>
                    </div>
                  </div>
                </Col>
              </NavLink>
              <Col md={2} style={{ margin: "auto", marginLeft: "7px" }}>
                {isActivePerfil ? (
                  <FiChevronUp
                    size={20}
                    onClick={() => setIsActivePerfil(!isActivePerfil)}
                  />
                ) : (
                  <FiChevronDown
                    size={20}
                    onClick={() => setIsActivePerfil(!isActivePerfil)}
                  />
                )}
              </Col>
            </Row>

            {isActivePerfil ? (
              <NavLink
                className="navigate-button"
                to={"/cambiar-contrasena"}
                activeclassname="active"
              >
                <div className="d-flex textoMenuLateral">
                  <span style={{ paddingLeft: "30px" }}>
                    Cambiar contraseña
                  </span>
                </div>
              </NavLink>
            ) : null}
            <div className="separadorItem" />
            <NavLink
              className="navigate-button"
              to={"/grupo-familiar"}
              activeclassname="active"
            >
              <div className="d-flex textoMenuLateral">
                <img
                  src={FaGrupoFamiliar}
                  className="iconosAtencion"
                  alt="..."
                />{" "}
                <span>Grupo familiar</span>
              </div>
            </NavLink>
            <div className="separadorItem" />
            <NavLink
              className="navigate-button"
              to={"/turnos"}
              activeclassname="active"
            >
              <div className="d-flex textoMenuLateral">
                <img src={FaTurnos} className="iconosAtencion" alt="..." />{" "}
                <span>Turnos</span>
              </div>
            </NavLink>
            <div className="separadorItem" />
            <NavLink
              className="navigate-button"
              to={"/resultados"}
              activeclassname="active"
            >
              <div className="d-flex textoMenuLateral">
                <img src={FaResultados} className="iconosAtencion" alt="..." />{" "}
                <span>Resultados</span>
              </div>
            </NavLink>
            <div className="separadorItem" />
            <NavLink
              className="navigate-button"
              to={"/certificado-asistencia"}
              activeclassname="active"
            >
              <div className="d-flex textoMenuLateral">
                <img
                  src={FaCertificados}
                  className="iconosAtencion"
                  alt="..."
                />{" "}
                <span>Certificados</span>
              </div>
            </NavLink>
            <div className="separadorItem" />
            <a
              className="navigate-button"
              activeclassname="active"
              onClick={handleRedirect}
            >
              <div className="d-flex textoMenuLateral">
                <img
                  src={HospitalBritanico}
                  className="iconosAtencion"
                  alt="..."
                />{" "}
                <span>Hospital Británico</span>
              </div>
            </a>

            <div className="separadorItem" />
            <NavLink
              className="navigate-button"
              to={"/atencion-al-paciente"}
              activeclassname="active"
            >
              <div className="d-flex textoMenuLateral">
                <img
                  src={FaAtencion}
                  className="iconosAtencion"
                  alt="..."
                  // style={{ size: "80px" }}
                />{" "}
                <span>Atención al paciente</span>
              </div>
            </NavLink>
            <div className="separadorItem" />
            <NavLink
              className="navigate-button"
              to={"/ayuda"}
              activeclassname="active"
            >
              <div className="d-flex textoMenuLateral">
                <img
                  src={FaAyuda}
                  className="iconosAtencion"
                  alt="..."
                  // style={{ size: "80px" }}
                />{" "}
                <span>Ayuda</span>
              </div>
            </NavLink>
            {user?.user?.data?.Customers?.pshb && (
              <>
                <div className="separadorTitulo" style={{ width: "100%" }} />
                <span
                  style={{
                    fontSize: "14px",
                    color: "#83888D",
                    padding: "10px 0px 0px 20px",
                  }}
                >
                  PLAN DE SALUD
                </span>
                <Row style={{ width: "100%", marginLeft: 0 }}>
                  <NavLink
                    className="navigate-button"
                    to={"/facturas"}
                    activeClassName="active"
                    style={{ width: "78%" }}
                  >
                    <Col md={10}>
                      <div className="d-flex textoMenuLateral">
                        <div>
                          <img
                            src={FaFacturacion}
                            className="iconosAtencion"
                            alt="..."
                          />{" "}
                          <span>Facturación</span>
                        </div>
                      </div>
                    </Col>
                  </NavLink>
                  <Col md={2} style={{ margin: "auto", marginLeft: "7px" }}>
                    {isActiveFacturacion ? (
                      <FiChevronUp
                        size={20}
                        onClick={() =>
                          setIsActiveFacturacion(!isActiveFacturacion)
                        }
                      />
                    ) : (
                      <FiChevronDown
                        size={20}
                        onClick={() =>
                          setIsActiveFacturacion(!isActiveFacturacion)
                        }
                      />
                    )}
                  </Col>
                </Row>
                {isActiveFacturacion ? (
                  <>
                    <NavLink
                      className="navigate-button"
                      to={"/debito-automatico"}
                      activeclassname="active"
                    >
                      <div className="d-flex textoMenuLateral">
                        <span style={{ paddingLeft: "30px" }}>
                          En tarjeta de crédito
                        </span>
                      </div>
                    </NavLink>
                    <NavLink
                      className="navigate-button"
                      to={"/debito-cuenta"}
                      activeclassname="active"
                    >
                      <div className="d-flex textoMenuLateral">
                        <span style={{ paddingLeft: "30px" }}>
                          En cuenta bancaria
                        </span>
                      </div>
                    </NavLink>
                    <NavLink
                      className="navigate-button"
                      to={"/historial-debito"}
                      activeclassname="active"
                    >
                      <div className="d-flex textoMenuLateral">
                        <span style={{ paddingLeft: "30px" }}>Historial</span>
                      </div>
                    </NavLink>
                  </>
                ) : null}
                <div className="separadorItem" />
                <div
                  className="navigate-button"
                  // to={"/perfil"}
                  activeclassname="active"
                >
                  <div
                  // className={`accordion-item ${
                  //   isActive && content[0] ? "accordion-item-open" : ""
                  // }`}
                  >
                    <div className="d-flex textoMenuLateral">
                      <div>
                        <img
                          src={FaReintegros}
                          className="iconosAtencion"
                          alt="..."
                        />{" "}
                        <span>Reintegros</span>
                      </div>
                      {/* {content[0] && ( */}
                      <div style={{ marginLeft: "auto", marginRight: "11px" }}>
                        {isActiveReintegro ? (
                          <FiChevronUp
                            size={20}
                            onClick={() =>
                              setIsActiveReintegro(!isActiveReintegro)
                            }
                          />
                        ) : (
                          <FiChevronDown
                            size={20}
                            onClick={() =>
                              setIsActiveReintegro(!isActiveReintegro)
                            }
                          />
                        )}
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
                {isActiveReintegro ? (
                  <>
                    <NavLink
                      className="d-flex navigate-button textoMenuLateral"
                      style={{
                        fontSize: "20px",
                        width: "100%",
                      }}
                      to={"/reintegro-estado"}
                      activeclassname="active"
                    >
                      <span style={{ paddingLeft: "30px" }}>Estado</span>
                    </NavLink>
                    <NavLink
                      className="d-flex navigate-button textoMenuLateral"
                      style={{
                        fontSize: "20px",
                        width: "100%",
                      }}
                      to={"/reintegro-solicitud"}
                      activeclassname="active"
                    >
                      <span style={{ paddingLeft: "30px" }}>Solicitud</span>
                    </NavLink>
                  </>
                ) : null}
                <div className="separadorItem" />
                <NavLink
                  className="navigate-button"
                  to="/credencial-digital"
                  activeclassname="active"
                >
                  <div className="d-flex textoMenuLateral">
                    <img
                      src={FaCredencial}
                      className="iconosAtencion"
                      alt="..."
                      // style={{ size: "80px" }}
                    />{" "}
                    <span>Credencial</span>
                  </div>
                </NavLink>
              </>
            )}

            <div className="separadorTitulo" style={{ width: "100%" }} />
            <NavLink
              className="navigate-button"
              to={"/cerrar-sesion"}
              activeclassname="active"
            >
              <div className="d-flex textoMenuLateral">
                <img
                  src={FaLogOut}
                  className="iconosAtencion"
                  alt="..."
                  // style={{ size: "80px" }}
                />{" "}
                <span>Cerrar sesión</span>
              </div>
            </NavLink>
          </div>
        </div>
      </aside>
    </>
  );
};

export default UserSidebar;
