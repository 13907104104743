import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { CUSTOM_SELECT_COLOR_WHITE } from "../../../common/customSelect/constants";
import CustomSelect from "../../../common/customSelect";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";
import "./editar-familiar.scss";
import { SwalMessage } from "../../../../utils/sweet-alert/sweet-alert";
import axios from "axios";
import { myAxios } from "../../../../utils/fetch";
import {
  nameLastnameValidator,
  nameLastnameValidatorSpace,
  passportValidation,
} from "../../../../utils/hooks/regex";

import EliminarIcon from "../../../../assets/img/eliminar.svg";
import VerIcon from "../../../../assets/img/card-icons/previsualizar.svg";
import Swal from "sweetalert2";

export const EditarFamiliarComponent = ({
  visible = false,
  onToggle,
  memberSelected,
  propArchivoDU,
  propArchivoPartida,
}) => {
  const [isAddFamily, setAddFamily] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const {
    sitePSHB,
    setSitePSHB,
    userPSHB,
    setUserPSHB,
    user,
    setUser,
    setFamiliarGroupComponent,
  } = useContext(UserGlobalContextMemorySpace);
  const [loader, setLoader] = useState(false);
  const [documentoErrorMsg, setDocumentoErrorMsg] = useState("");
  /* create familiar Group*/
  const date = new Date();
  // const [maxDate, setMaxDate] = useState(`${date.getFullYear()}-${((date.getMonth()+1).toString() == 1 ? date.getMonth()+1 : `0${date.getMonth()+1}`)}-${date.getUTCDate()}`);
  const [maxDate, setMaxDate] = useState(
    `${date.getFullYear()}-${
      (date.getMonth() + 1).toString().length == 1
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`
    }-${
      date.getUTCDate().toString().length == 1
        ? `0${date.getUTCDate()}`
        : `${date.getMonth()}`
    }`
  );

  const [minDate, setMinDate] = useState(
    `${date.getFullYear() - 18}-${
      (date.getMonth() + 1).toString().length == 1
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`
    }-${
      date.getUTCDate().toString().length == 1
        ? `0${date.getUTCDate()}`
        : `${date.getMonth()}`
    }`
  );

  const [fechaNac, setFechaNac] = useState(memberSelected.birthday);
  const [formatDate, setFormatDate] = useState(memberSelected.birthday);
  const [dateType, setDateType] = useState("text");
  const [fechaNacPlaceholder, setFechaNacPlaceholder] = useState("");
  const [nombre, setNombre] = useState(memberSelected.firstName);
  const [apellido, setApellido] = useState(memberSelected.lastName);
  const [parentesco, setParentesco] = useState(memberSelected.relationshipid);
  const [genero, setGenero] = useState(
    memberSelected.genderCode === "H" ? 2 : 1
  );
  const [docType, setDocType] = useState(memberSelected.dniType);
  const [documento, setDocumento] = useState(memberSelected.dni);
  const [credencial, setCredencial] = useState(memberSelected.credential);
  const [credencialNew, setCredencialNew] = useState(memberSelected.credential);
  const [errorMsg, setErrorMsg] = useState();
  const [dateErrorMsg, setDateErrorMsg] = useState("");
  
  const [archivoDU, setArchivoDU] = React.useState(propArchivoDU);
  const [archivoPartida, setArchivoPartida] = React.useState(
    propArchivoPartida
  );

  //const [archivoDUName,setArchivoDUName]=React.useState(propArchivoDU?.filename)
  //const [archivoPartidaName,setArchivoPartidaName]=React.useState(propArchivoPartida?.filename)


  const fileTypes = ["application/pdf", "image/png", "image/jpg", "image/jpeg"];
  const [fileSelectedPartida, setFilePartida] = React.useState({
    label: "",
    error: false,
  });
  const [fileSelected, setFile] = React.useState({ label: "", error: false });
  
  
  

  // const handlerDate = (value) => {
  //   if (value.length <= 10) {
  //     setFechaNac(value);

  //     setFormatDate(
  //       `${value.slice(-2)}-${value.slice(5, 7)}-${value.slice(0, 4)}`
  //     );
  //   }
  // };

  const handlerDate = (value) => {
    if (value.length <= 10) {
      setFechaNac(value);
    }
  };

  const handlerDateBlur = (value) => {
    const date1 = new Date(value);
    const date2 = new Date(minDate);
    const date3 = new Date(maxDate);

    if (date1.getTime() < date2.getTime()) {
      setDateErrorMsg(
        "Solo puede agregarse como miembros de un grupo familiar a menores de 18 años"
      );
    } else if (date1.getTime() > date3.getTime()) {
      setDateErrorMsg("Fecha inválida");
    } else if (value == "") {
      setDateErrorMsg("Campo obligatorio.");
    } else {
      setDateErrorMsg("");
    }
  };

  const handlerDocumentoBlur = (value) => {
    if (docType == "0") {
      if (documento < 1000000) {
        setDocumentoErrorMsg("Nº de documento inválido");
      } else {
        setDocumentoErrorMsg("");
      }
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const seleccionarArchivo = (fileType) => {
    setErrorMsg("");
    var input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.jpeg,.png,.pdf";
    input.onchange = async (e) => {
      var file = e.target.files[0];

      if (fileTypes.indexOf(file.type) === -1) {
        fileType == "partida"
          ? setFilePartida({
              label: "",
              error: true,
              errorMessage: "Solo se permiten imagenes JPG,JPEG,PNG y PDF",
            })
          : setFile({
              label: "",
              error: true,
              errorMessage: "Solo se permiten imagenes JPG,JPEG,PNG y PDF",
            });
        setErrorMsg("Solo se permiten imagenes JPG,JPEG,PNG y PDF.");
        return;
      }

      if (file.size > 3000000) {
        fileType == "partida"
          ? setFilePartida({
              label: "",
              error: true,
              errorMessage:
                "Por favor verifica que tu archivo pese menos de 3 MB",
            })
          : setFile({
              label: "",
              error: true,
              errorMessage:
                "Por favor verifica que tu archivo pese menos de 3 MB",
            });
        setErrorMsg("Por favor verifica que tu archivo pese menos de 3 MB");
        return;
      }

      try {
        const result = await toBase64(file);
        let aux = [];
        aux = result.split(",");
        fileType == "partida"
          ? setFilePartida({
              label: file.name,
              error: false,
              file: file,
              filebase64: aux[1],
            })
          : setFile({
              label: file.name,
              error: false,
              file: file,
              filebase64: aux[1],
            });
        fileType == "partida"
          ? setArchivoPartida({...archivoPartida,filebase64:aux[1]})
          : setArchivoDU({...archivoDU,filebase64:aux[1]});
      } catch (error) {
        fileType == "partida"
          ? setFilePartida({
              label: "Error",
              error: true,
              errorMessage:
                "Detectamos un error, por favor intenta nuevamente en unos minutos.",
            })
          : setFile({
              label: "Error",
              error: true,
              errorMessage:
                "Detectamos un error, por favor intenta nuevamente en unos minutos.",
            });
        setErrorMsg(
          "Detectamos un error, por favor intenta nuevamente en unos minutos."
        );
        return;
      }
    };

    input.click();
  };

  const cleanCreateFamilyGroupForm = () => {
    setNombre("");
    setApellido("");
    setGenero("");
    setParentesco("");
    setFechaNac("");
    setCredencial("");
    setArchivoDU("");
    setArchivoPartida("");
    setDocType("");
    setDocumento("");
    setFilePartida("");
    setFile("");
  };

  // var documentList = [{ id: 1, name: "DNI" }];

  // var genderList = [
  //   { id: 1, name: "Masculino" },
  //   { id: 1, name: "Femenino" },
  // ];

  const handleEditGF = async (event) => {
    event.preventDefault();
    setErrorMsg("");

    if (
      !nombre ||
      !apellido ||
      !parentesco ||
      !genero ||
      !docType ||
      !documento ||
      !fechaNac ||
      !archivoDU ||
      !archivoPartida ||
      !credencialNew ||
      dateErrorMsg ||
      documentoErrorMsg
    ) {
      setErrorMsg(
        "Por favor ingresa todos los datos solicitados para avanzar."
      );
    } else {
      setLoader(true);

      

      let credential = credencial;
      if (credencialNew) {
        credential = credencialNew;
      }

      let appToken = user.user.data.appToken;
      let idCustomer = user.user.data.Customers.idCustomer;
      let id = memberSelected.id;
      let familygroupId = memberSelected.familygroupId;

      let res = await myAxios({
        url:
        process.env.REACT_APP_GATEWAY_QA +
          "/customers/api/v1/familiargroup/updatefamilymember",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appToken}`,
        },
        data: {
          idCustomer: idCustomer,
          familyMember: {
            id: id,
            familygroupId: familygroupId,
            relationshipid: parentesco,
            firstName: nombre,
            lastName: apellido,
            birthday: formatDate,
            dni: documento,
            dniType: docType,
            genderid: genero,
            fileDU: fileSelected.filebase64,
            fileDocLegal: "",
            filePartida: fileSelectedPartida.filebase64,
            credential: credential,
            statusCode: "PDA",
          },
        },
      })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (
            error.response.data.Message.Generic ==
            "El miembro de grupo familiar que intenta actualizar no existe."
          ) {
            setErrorMsg(
              "Los datos ingresados corresponden a un miembro de grupo familiar ya existente."
            );
          } else {
            setErrorMsg(
              "Ocurrió un error al enviar los datos, por favor intente nuevamente en breves minutos.."
            );
          }
          setLoader(false);
        });

      if (res.data.responseCode == 200) {
        setFamiliarGroupComponent(res.data.data.FamilyGroup.familyMembers);
        SwalMessage(
          "success",
          '<h5 style="color: #224d7c"></h5>',
          "Miembro del grupo familiar cargado con éxito.",
          "Continuar"
        );
      } else if (
        res.data.responseCode == 500 &&
        res.data.Message.Generic ==
          "El miembro de grupo familiar que intenta crear ya existe."
      ) {
        setErrorMsg(
          "El miembro de grupo familiar que intenta crear ya existe."
        );
      } else {
        setErrorMsg(
          "Ocurrió un error al enviar los datos, por favor intente nuevamente en breves minutos."
        );
      }

      setLoader(false);
      cleanCreateFamilyGroupForm();
      onToggle();
    }
  };

  useEffect(() => {
    //Mount:

    // cleanCreateFamilyGroupForm();
    return () => {
      //Unmount
    };
  }, []);

  const handleOnChangeDocType = (value) => {
    setDocType(value);
    setDocumento("");
  };

  const descargarArchivo = (type) => {
    if (type === "DU") {
      if(archivoDU===propArchivoDU)
      {
        const linkSource = `data:${propArchivoDU.filetype};base64,${propArchivoDU.filebase64}`;
        const downloadLink = document.createElement("a");
        const fileName = propArchivoDU.filename;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }else
      {
        const downloadLink = URL.createObjectURL(fileSelected.file);
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = fileSelected.file.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } else {
      if(archivoPartida===propArchivoPartida)
      {
        const linkSource = `data:${propArchivoPartida.filetype};base64,${propArchivoPartida.filebase64}`;
        const downloadLink = document.createElement("a");
        const fileName = propArchivoPartida.filename;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }else
      {
        const downloadLink = URL.createObjectURL(fileSelectedPartida.file);
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = fileSelectedPartida.file.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  };

  const eliminarArchivo = (type) => {    
    if(type==="DU")
    {
      setArchivoDU(null)      
      setFile({ label: "", error: false })
    }else
    {
      setArchivoPartida(null)   
      setFilePartida({ label: "", error: false })   
    }
    
  };

  return (
    <>    
    <Modal
      className="mobile-modal"
      size={"lg"}
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      centered
      onHide={onToggle}
    >
      <form onSubmit={handleEditGF} className="formulario-edicion-grupo-familiar">
        <Modal.Body>
          <div className="header-familygroup-information">
            <span className="titulo-editar-familiar">
              Editar datos del paciente
            </span>
          </div>

          <div className="barra-editar-familiar w-100" />

          <div className="">
            <div className="row mb-2">
              <div className="col-md-4">
                <span className="hb-label">Nombre</span>
              </div>
              <div className="col-md-8">
                <input
                  maxLength={50}
                  onChange={(e) =>
                    setNombre(
                      !nombre
                        ? nameLastnameValidator(e.target.value)
                        : nameLastnameValidatorSpace(e.target.value)
                    )
                  }
                  value={nombre}
                  type="text"
                  className="input-login w-100 shadow-blue"
                />
                {!nombre ? (
                  <p
                    className="hb-label error-message"
                    style={{
                      color: "red",
                      marginTop: "5px",
                      marginLeft: "17px",
                      fontSize: "14px", // Agrega esta línea
                    }}
                  >
                    Campo obligatorio.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <span className="hb-label">Apellido</span>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  className="input-login w-100 shadow-blue"
                  maxLength={50}
                  onChange={(e) =>
                    setApellido(
                      !apellido
                        ? nameLastnameValidator(e.target.value)
                        : nameLastnameValidatorSpace(e.target.value)
                    )
                  }
                  value={apellido}
                />
                {!apellido ? (
                  <p
                    className="hb-label error-message"
                    style={{
                      color: "red",
                      marginTop: "5px",
                      marginLeft: "17px",
                      fontSize: "14px", // Agrega esta línea
                    }}
                  >
                    Campo obligatorio.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <span className="hb-label">Parentesco</span>
              </div>
              <div className="col-md-8">
                <select
                  className={`input-login shadow-blue`}
                  value={parentesco}
                  style={{ width: "100%" }}
                  onChange={(e) => setParentesco(e.target.value)}
                >
                  <option className="option-default-size" value="" hidden>
                    Seleccionar
                  </option>
                  <option value="1">Madre</option>
                  <option value="2">Padre</option>
                  <option value="3">Tutor a cargo</option>
                  <option value="9">Hijo menor permanente</option>
                </select>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <span className="hb-label">Género</span>
              </div>
              <div className="col-md-8">
                <select
                  value={genero}
                  className={`input-login shadow-blue`}
                  style={{ width: "100%" }}
                  onChange={(e) => setGenero(e.target.value)}
                >
                  <option className="option-default-size" value="" hidden>
                    Seleccionar
                  </option>
                  <option value="1">Femenino</option>
                  <option value="2">Masculino</option>
                </select>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <span className="hb-label">Fecha de nacimiento</span>
              </div>
              <div className="col-md-8">
                <input
                  type={dateType}
                  onFocus={() => setDateType("date")}
                  value={fechaNac}
                  defaultValue={fechaNac}
                  min={minDate}
                  max={maxDate}
                  className={`input-login shadow-blue ${fechaNacPlaceholder}`}
                  onChange={(e) => handlerDate(e.target.value)}
                  onBlur={(e) => handlerDateBlur(e.target.value)}
                  style={{ width: "100%" }}
                ></input>
                {dateErrorMsg ? (
                  <p
                    className="hb-label error-message"
                    style={{
                      color: "red",
                      marginTop: "5px",
                      marginLeft: "17px",
                      fontSize: "14px", // Agrega esta línea
                    }}
                  >
                    {dateErrorMsg}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="">
            <div className="row mb-2">
              <div className="col-md-4">
                <span className="hb-label">Tipo de documento</span>
              </div>
              <div className="col-md-8">
                <select
                  value={docType}
                  className={`input-login shadow-blue`}
                  style={{ width: "100%" }}
                  onChange={(e) => handleOnChangeDocType(e.target.value)}
                >
                  <option className="option-default-size" value="" hidden>
                    Seleccionar
                  </option>
                  <option value="0">DNI</option>
                  <option value="2">Pasaporte</option>
                </select>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <span className="hb-label">N° Documento</span>
              </div>
              <div className="col-md-8">
                <input
                  maxLength={docType == "2" ? 20 : 8}
                  type="text"
                  className="input-login w-100 shadow-blue"
                  onChange={(e) =>
                    docType == "2"
                      ? setDocumento(passportValidation(e.target.value))
                      : setDocumento(e.target.value.replace(/[^0-9]/g, ""))
                  }
                  onBlur={(e) => handlerDocumentoBlur(e.target.value)}
                  value={documento}
                />
                {documentoErrorMsg ? (
                  <p
                    className="hb-label error-message"
                    style={{
                      color: "red",
                      marginTop: "5px",
                      marginLeft: "17px",
                      fontSize: "14px", // Agrega esta línea
                    }}
                  >
                    {documentoErrorMsg}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <span className="hb-label">N° Credencial</span>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  className="input-login w-100 shadow-blue"
                  maxLength={20}
                  onChange={(e) =>
                    setCredencialNew(
                      // e.target.value.replace(/[^0-9]/g, "")
                      e.target.value
                    )
                  }
                  value={credencialNew}
                />
                {!credencialNew ? (
                  <p
                    className="hb-label error-message"
                    style={{
                      color: "red",
                      marginTop: "5px",
                      marginLeft: "17px",
                      fontSize: "14px", // Agrega esta línea
                    }}
                  >
                    Campo obligatorio.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <span
                  className="hb-label"
                  style={
                    fileSelected.error ? { color: "red" } : { color: "#14365a" }
                  }
                >
                  Documento{" "}
                </span>
              </div>
              <div className="col-md-8 d-flex">
                <div style={archivoDU?{width:"calc(100% - 65px)"}:{width:"100%"}}>
                  <div
                    className="custom-load-icon"
                    onClick={() => seleccionarArchivo("")}
                  >
                    <FontAwesomeIcon icon={faPaperclip} />
                    <span>Adjuntar</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "100px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className={
                          (fileSelected.error ? "error-file " : "") +
                          "round-label w-100 shadow-blue"
                        }
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "15px",
                          paddingLeft: "40px",
                        }}
                      >
                        {(() => {
                          if (fileSelected.file && !fileSelected.error)
                            return fileSelected.label;
                          else if (archivoDU != null)
                            return archivoDU.filename;
                          else return "";
                        })()}
                      </span>
                    </div>
                  </div>
                  {(fileSelected.file && !fileSelected.error) ||
                  archivoDU != null ? (
                    <></>
                  ) : (
                    <span
                      className="hb-label error-message"
                      style={{
                        color: "red",
                        marginTop: "5px",
                        marginLeft: "17px",
                        fontSize: "14px", // Agrega esta línea
                      }}
                    >
                      Campo obligatorio.
                      {/* {documentoErrorMsg} */}
                    </span>
                  )}
                </div>
                {(archivoDU!=null) &&
                <div className="d-flex justify-content-center gap-2 ps-2 pe-2" style={{width:"65px",marginLeft:"10px"}}>
                  <img
                    className=""
                    style={{ cursor: "pointer" }}
                    src={VerIcon}
                    alt="Ver archivo"
                    onClick={() => {
                      descargarArchivo("DU");
                    }}
                  />
                  <img
                    className=""
                    style={{ cursor: "pointer" }}
                    src={EliminarIcon}
                    alt="Eliminar archivo"
                    onClick={() => {
                      eliminarArchivo("DU");
                    }}
                  />
                </div>
                }

              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <span
                  className="hb-label"
                  style={
                    fileSelectedPartida.error
                      ? { color: "red" }
                      : { color: "#14365a" }
                  }
                >
                  Partida de nacimiento
                </span>
              </div>
              <div className="col-md-8 d-flex">
                <div style={archivoPartida!=null?{width:"calc(100% - 65px)"}:{width:"100%"}}>
                  <div
                    className="custom-load-icon"
                    onClick={() => seleccionarArchivo("partida")}
                  >
                    <FontAwesomeIcon icon={faPaperclip} />
                    <span>Adjuntar</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "100px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className={
                          (fileSelectedPartida.error ? "error-file " : "") +
                          "round-label w-100 shadow-blue"
                        }
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "15px",
                          paddingLeft: "40px",
                        }}
                      >
                        {/* {fileSelectedPartida.file && !fileSelectedPartida.error
                          ? fileSelectedPartida.label //.slice(0, 5) + "..."
                          : ""} */}

                        {(() => {
                          if (
                            fileSelectedPartida.file &&
                            !fileSelectedPartida.error
                          )
                            return fileSelectedPartida.label;
                          else if (archivoPartida != null)
                            return archivoPartida.filename
                          else return "";
                        })()}
                      </span>
                    </div>

                    {/* {fileSelectedPartida.error
                                        ?
                                            <span className={fileSelectedPartida.error?"error-file":""} style={{marginLeft:"-90px",fontSize:"10px"}}>{fileSelectedPartida.errorMessage}</span>
                                        :
                                            <></>

                                        } */}
                  </div>
                  {(fileSelectedPartida.file && !fileSelectedPartida.error) ||
                  archivoPartida != null ? (
                    <></>
                  ) : (
                    <span
                      className="hb-label error-message"
                      style={{
                        color: "red",
                        marginTop: "5px",
                        marginLeft: "17px",
                        fontSize: "14px", // Agrega esta línea
                      }}
                    >
                      Campo obligatorio.
                      {/* {documentoErrorMsg} */}
                    </span>
                  )}
                </div>
                {(archivoPartida!=null) &&
                <div className="d-flex justify-content-center gap-2 ps-2 pe-2" style={{width:"65px",marginLeft:"10px"}}>
                  <img
                    className=""
                    style={{ cursor: "pointer" }}
                    src={VerIcon}
                    alt="Ver archivo"
                    onClick={() => {
                      descargarArchivo("PARTIDA");
                    }}
                  />
                  <img
                    className=""
                    style={{ cursor: "pointer" }}
                    src={EliminarIcon}
                    alt="Eliminar archivo"
                    onClick={() => {
                      eliminarArchivo("PARTIDA");
                    }}
                  />
                </div>
                }


              </div>
            </div>
          </div>

          <div className="row mt-5">
            {loader ? (
              <>
                <div className="col-md-12 family-add-buttons">
                  <span className="spinner-grow spinner-grow-sm"></span>
                  <span className="spinner-grow spinner-grow-sm"></span>
                  <span className="spinner-grow spinner-grow-sm"></span>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-12 family-add-buttons">
                  <div
                    className="btn btn-hb-secondary-without-border"
                    onClick={() => 
                      {
                        //onToggle()
                        if(archivoDU!=null && archivoPartida!=null)
                        {
                          onToggle()
                          return
                        }

                                    
                          
                          Swal.fire({
                            icon: "error",
                            title: "",
                            text: "Los cambios realizados se perderán",
                            color: '#224d7c',
                            confirmButtonColor: '#224d7c',
                            confirmButtonText: "Aceptar",                            
                            cancelButtonText:"Cancelar",
                            cancelButtonClass:"btn btn-hb-secondary-without-border",
                            confirmButtonClass:"btn btn btn-hb-primary-without-border shadow-blue",
                            showCancelButton:true,                            
                        }).then(response=>{
                           if(response.isConfirmed)
                           {                            
                               onToggle()
                           }
                        })
                        }
                      
                    }
                  >
                    Cancelar
                  </div>
                  {/* <div className="btn btn-hb-primary-without-border" onClick={handleEditGF}>Aceptar</div> */}
                  <input
                    disabled={archivoDU==null || archivoPartida==null}
                    className="btn btn btn-hb-primary-without-border shadow-blue"
                    type="submit"
                    value="Guardar"
                  />
                </div>
              </>
            )}
          </div>
          <Row>
            <div className="d-flex justify-content-center">
              <p style={{ color: "red", fontWeight: "500"}}>
                {errorMsg}
              </p>
            </div>
          </Row>

          {(archivoDU==null || archivoPartida==null) && <Row>
            <div className="d-flex justify-content-center">
              <p style={{ color: "red", fontWeight: "500", fontSize:"14px" }}>
              Todos los campos son obligatorios. Si desea restablecer los campos, haga click en Cancelar.        
              </p>
            </div>
          </Row>}
        </Modal.Body>
      </form>
    </Modal>
    </>
  );
};
