import { useContext, useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import logo from "./logo.svg";
import {
  BrowserRouter,
  Routes,
  Route,
  NavLink,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import {
  UserGlobalContext,
  UserGlobalContextMemorySpace,
} from "./contexts/user/userGlobalContext";
import Login from "./components/common/login";
import NavBarHB from "./components/hb/navbar";
import NavBarPSHB from "./components/pshb/navbar";
import HomePSHB from "./pages/pshb/home";
import HomeHB from "./pages/hb/home";
import LoginHB from "./pages/hb/login";
import RegisterHB from "./pages/hb/register";
import CommonLogin from "./components/common/login";
import CommonRegister from "./components/common/register";
import CommonFooter from "./components/common/footer";
import CommonPasswordRecovery from "./components/common/password-recovery";
import CommonDeleteAccount from "./components/common/delete-account";
import Results from "./pages/hb/results";
import Perfil from "./pages/hb/perfil";
import PerfilPSHB from "./pages/pshb/perfil";
import Ayuda from "./pages/hb/ayuda";
import Invoices from "./pages/pshb/invoices";
import AutomaticDebit from "./pages/pshb/automatic-debit";
import DebitAccount from "./pages/pshb/debit-account";
import AtencionAlPaciente from "./pages/hb/atencion-al-paciente";
import CertificadoDeAsistencia from "./pages/hb/atencion-al-paciente/certificado-asistencia";
import FamilyGroupHB from "./pages/hb/family-group";
import Refund from "./pages/pshb/refund";
import LogosComponent from "./components/common/logos/logos";
import AddRefundComponent from "./components/pshb/add-refund";
import RefundComponent from "./components/pshb/refund";
import LogoutPSHB from "./pages/pshb/logout";
import LogoutHB from "./pages/hb/logout";
import AyudaHB from "./pages/hb/ayuda";
import AyudaPS from "./pages/pshb/ayuda";
import CambiarContrasenaHB from "./pages/hb/cambiar-contrasena";
import CambiarContrasenaPSHB from "./pages/pshb/password-recovery";
import ContactoPS from "./pages/pshb/contacto";
import AutorizacionesPSHB from "./pages/pshb/autorizaciones";
import FamilyGroupPSHB from "./pages/pshb/grupo-familiar";
import CredencialDigitalPSHB from "./pages/pshb/credencial-digital";
import CertificadoAsistencia from "./pages/hb/atencion-al-paciente/certificado-asistencia";
import CertificadoAsistenciaPSHB from "./pages/pshb/certificado-asistencia";
import ReintegrosEstado from "./pages/pshb/refund";
import ReintegrosSolicitud from "./pages/pshb/refund/refund-solicitud";
import ValidateCredential from "./pages/pshb/validate-credential";
import InformacionUtil from "./pages/pshb/informacion-util";
import Turnos from "./pages/hb/turnos";
import SugerenciasHB from "./components/hb/libro-sugerencias";
import axios from "axios";
import CommonNotFound from "./components/common/not-found";
import SocialMediaLink from "./pages/common/socialmedialink";
import MainCommonLoader from "./components/common/main-loader";
import ErrorBoundary from "./components/common/exception/error";
import ReactGA from "react-ga4";
import PagaFactura from "./components/pshb/paga-factura";
import HistorialDebito from "./pages/pshb/historial-debito";
import NoAutorizado from "./components/pshb/no-autorizado";
import UserSidebar from "./components/common/user-sidebar";
import DOMPurify from "dompurify";

const refreshAccessToken = () => {};

// Borra los console logs para etapa de prueba 20/10
// console.log = function () {};

ReactGA.initialize("G-WSTZY7HJ3M");

DOMPurify.setConfig({
  ADD_ATTR: ["target"],
});

const App = () => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    sitePSHB,
    setSitePSHB,
    userPSHB,
    setUserPSHB,
    user,
    setUser,
    mainLoader,
    setMainLoader,
  } = useContext(UserGlobalContextMemorySpace);

  const [searchParams, setSearchParams] = useSearchParams({});
  const pshb = searchParams.get("pshb");
  const shr = searchParams.get("SHR");
  // const source=searchParams.get("source");
  let pathName = location.pathname;

  const routesExcluded = ["/", "/cerrar-sesion"];

  //const urlsPermitidas=["/resultados"]

  useEffect(() => {
    //Mount:
    setSitePSHB(false);
    if (pathName != "/" && pathName != "/cerrar-sesion") {
      let openDialog = searchParams.get("open");
      if (openDialog && !pathName.includes("?open")) {
        pathName += "?open=1";
      }
      sessionStorage.setItem("redirect", pathName);
    }

    //ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });

    return () => {
      //Unmount
    };
  }, [searchParams]);

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      setHeight(ref.current.clientHeight);
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (
    <>
      {mainLoader ? (
        <>
          <MainCommonLoader />
        </>
      ) : (
        <>
          {!user ? (
            /* STACK NOT LOGGED IN */
            <Routes>
              <Route
                path="/"
                element={
                  <ErrorBoundary>
                    <CommonLogin />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/register"
                element={
                  <ErrorBoundary>
                    <CommonRegister />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/password-recovery"
                element={
                  <ErrorBoundary>
                    <CommonPasswordRecovery />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/delete-account"
                element={
                  <ErrorBoundary>
                    <CommonDeleteAccount />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/credential-validation"
                element={shr ? <ValidateCredential /> : <CommonLogin />}
              />
              <Route
                path="/socialmedialink"
                element={
                  <ErrorBoundary>
                    <SocialMediaLink />
                  </ErrorBoundary>
                }
              />
              <Route
                path="*"
                element={
                  <ErrorBoundary>
                    <CommonLogin redirect={true} />
                  </ErrorBoundary>
                }
              />
            </Routes>
          ) : user ? (
            /* STACK HB - PORTAL DE PACIENTES */
            <>
              {shr && location.pathname == "/credential-validation" ? (
                <ValidateCredential user={user} />
              ) : location.pathname == "/password-recovery/" ? (
                <ErrorBoundary>
                  <CommonPasswordRecovery />
                </ErrorBoundary>
              ) : (
                <>
                  <NavBarHB></NavBarHB>
                  <div className="d-flex content-manager-site">
                    {!routesExcluded.includes(location.pathname) ? (
                      <UserSidebar height={height} />
                    ) : null}
                    <div
                      ref={ref}
                      className="scrollable-aside"
                      style={{ width: "100%" }}
                    >
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <ErrorBoundary>
                              <HomeHB></HomeHB>
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/facturas"
                          element={
                            <ErrorBoundary>
                              {user.user?.data?.Customers?.holder === "T" ? (
                                <Invoices></Invoices>
                              ) : (
                                <NoAutorizado />
                              )}
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/debito-automatico"
                          element={
                            <ErrorBoundary>
                              {user.user?.data?.Customers?.holder === "T" ? (
                                <AutomaticDebit></AutomaticDebit>
                              ) : (
                                <NoAutorizado />
                              )}
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/debito-cuenta"
                          element={
                            <ErrorBoundary>
                              {user.user?.data?.Customers?.holder === "T" ? (
                                <DebitAccount></DebitAccount>
                              ) : (
                                <NoAutorizado />
                              )}
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/historial-debito"
                          element={
                            <ErrorBoundary>
                              {user.user?.data?.Customers?.holder === "T" ? (
                                <HistorialDebito></HistorialDebito>
                              ) : (
                                <NoAutorizado />
                              )}
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/reintegro-estado"
                          element={
                            <ErrorBoundary>
                              <ReintegrosEstado />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/reintegro-solicitud"
                          element={
                            <ErrorBoundary>
                              <ReintegrosSolicitud />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/credencial-digital"
                          element={
                            <ErrorBoundary>
                              <CredencialDigitalPSHB />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/perfil"
                          element={
                            <ErrorBoundary>
                              <Perfil></Perfil>
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/grupo-familiar"
                          element={
                            <ErrorBoundary>
                              <FamilyGroupHB></FamilyGroupHB>
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/resultados"
                          element={
                            <ErrorBoundary>
                              <Results></Results>
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/ayuda"
                          element={
                            <ErrorBoundary>
                              <AyudaHB />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/cambiar-contrasena"
                          element={
                            <ErrorBoundary>
                              <CambiarContrasenaHB />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/atencion-al-paciente"
                          element={
                            <ErrorBoundary>
                              <AtencionAlPaciente></AtencionAlPaciente>
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/certificado-asistencia"
                          element={
                            <ErrorBoundary>
                              <CertificadoAsistencia></CertificadoAsistencia>
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/cerrar-sesion"
                          element={
                            <ErrorBoundary>
                              <LogoutHB />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/turnos"
                          element={
                            <ErrorBoundary>
                              <Turnos />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="sugerencias"
                          element={
                            <ErrorBoundary>
                              <SugerenciasHB></SugerenciasHB>
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/test"
                          element={
                            <ErrorBoundary>
                              <></>
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="*"
                          element={
                            <ErrorBoundary>
                              <CommonNotFound pshb={false} loggedIn={true} />
                            </ErrorBoundary>
                          }
                        />
                      </Routes>
                      <LogosComponent></LogosComponent>
                      <CommonFooter></CommonFooter>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>Sitio en Mantenimiento</>
          )}
        </>
      )}
    </>
  );
};

export default App;
