import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import style from "./style.scss";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { NavLink } from "react-router-dom";
import FaUserCircle from "../../../assets/img/hb/Perfil Menú.svg";
import FaProfessional from "../../../assets/img/hb/Profesionales y espe.  menú.svg";
import FaAtencion from "../../../assets/img/hb/Atención al paciente  menú.svg";
import FaPerfil from "../../../assets/img/hb/Perfil  menú.svg";
import FaInicio from "../../../assets/img/hb/Inicio  menú.svg";
import FaGrupoFamiliar from "../../../assets/img/hb/Grupo familiar  menú.svg";
import FaTurnos from "../../../assets/img/hb/Turnos  menú.svg";
import FaResultados from "../../../assets/img/hb/Resultados  menú.svg";
import FaCertificados from "../../../assets/img/hb/Certificado.svg";
import FaAyuda from "../../../assets/img/hb/Información  menú.svg";
import FaFacturacion from "../../../assets/img/hb/Facturacion.svg";
import FaReintegros from "../../../assets/img/hb/Reintegros.svg";
import FaCredencial from "../../../assets/img/hb/Credencial.svg";
import FaLogOut from "../../../assets/img/hb/Log out menú.svg";
import HospitalBritanico from "../../../assets/img/hb/Hospital Británico Home.svg";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Row, Col } from "react-bootstrap";

const DrawerHB = () => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);
  const [isActivePerfil, setIsActivePerfil] = useState(false);
  const [isActiveReintegro, setIsActiveReintegro] = useState(false);
  const [isActiveFacturacion, setIsActiveFacturacion] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleRedirect = () => {
    window.open("https://www.hospitalbritanico.org.ar/", "_blank");
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : "53vw" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="drawer"
    >
      <div style={{ minHeight: "100vh", backgroundColor: "#efefef" }}>
        <aside className="asideWebHBMobile">
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            <div
              className="d-flex align-items-center"
              style={{ backgroundColor: "#efefef", paddingBottom: "25px" }}
            >
              <img
                src={FaUserCircle}
                alt="..."
                style={{
                  height: "40px",
                  marginRight: "14px",
                  marginLeft: "25px",
                }}
              />
              <div
                style={{
                  color: "#224A8D",
                  fontFamily: "Roboto-Bold",
                  marginBottom: "0",
                  marginRight: "10px",
                  fontSize: "17px",
                }}
              >
                {user.user.data.Customers.firstName}{" "}
                {user.user.data.Customers.lastName}
              </div>
            </div>
            <div className="separadorTitulo" style={{ width: "100%" }} />
            <div
              className="d-flex flex-column justify-content-center"
              style={{ width: "100%", backgroundColor: "#efefef" }}
            >
              <NavLink
                className="navigate-button"
                to={"/"}
                activeclassname="active"
              >
                <div className="d-flex textoMenuLateral">
                  <img src={FaInicio} className="iconosAtencion" alt="..." />{" "}
                  <span>Inicio</span>
                </div>
              </NavLink>
              <div className="separadorItem" />
              <Row
                style={{ marginLeft: 0 }}
                onClick={(e) => {
                  if (
                    e.target.tagName.toLowerCase() === "svg" ||
                    e.target.tagName.toLowerCase() === "polyline"
                  ) {
                    e.stopPropagation();
                  }
                }}
              >
                <NavLink
                  className="navigate-button perfil-bottom"
                  to={"/perfil"}
                  activeclassname="active"
                  style={{ width: "72%" }}
                  onClick={(e) => {
                    if (
                      e.target.tagName.toLowerCase() === "svg" ||
                      e.target.tagName.toLowerCase() === "polyline"
                    ) {
                      e.stopPropagation();
                    }
                  }}
                >
                  <Col md={10}>
                    <div className="d-flex textoMenuLateral">
                      <div>
                        <img
                          src={FaPerfil}
                          className="iconosAtencion"
                          alt="..."
                        />{" "}
                        <span>Perfil</span>
                      </div>
                    </div>
                  </Col>
                </NavLink>
                <Col style={{ margin: "auto", marginLeft: "10px" }}>
                  {isActivePerfil ? (
                    <FiChevronUp
                      size={20}
                      onClick={() => setIsActivePerfil(!isActivePerfil)}
                    />
                  ) : (
                    <FiChevronDown
                      size={20}
                      onClick={() => setIsActivePerfil(!isActivePerfil)}
                    />
                  )}
                </Col>
              </Row>
              {isActivePerfil ? (
                <NavLink
                  className="navigate-button menuMobile"
                  to={"/cambiar-contrasena"}
                  activeclassname="active"
                >
                  <div className="d-flex textoMenuLateral">
                    <span style={{ paddingLeft: "30px" }}>
                      Cambiar contraseña
                    </span>
                  </div>
                </NavLink>
              ) : null}
              <div className="separadorItem" />
              <NavLink
                className="navigate-button"
                to={"/grupo-familiar"}
                activeclassname="active"
              >
                <div className="d-flex textoMenuLateral">
                  <img
                    src={FaGrupoFamiliar}
                    className="iconosAtencion"
                    alt="..."
                  />{" "}
                  <span>Grupo familiar</span>
                </div>
              </NavLink>
              <div className="separadorItem" />
              <NavLink
                className="navigate-button"
                to={"/turnos"}
                activeclassname="active"
              >
                <div className="d-flex textoMenuLateral">
                  <img src={FaTurnos} className="iconosAtencion" alt="..." />{" "}
                  <span>Turnos</span>
                </div>
              </NavLink>
              <div className="separadorItem" />
              <NavLink
                className="navigate-button"
                to={"/resultados"}
                activeclassname="active"
              >
                <div className="d-flex textoMenuLateral">
                  <img
                    src={FaResultados}
                    className="iconosAtencion"
                    alt="..."
                  />{" "}
                  <span>Resultados</span>
                </div>
              </NavLink>
              <div className="separadorItem" />
              <NavLink
                className="navigate-button"
                to={"/certificado-asistencia"}
                activeclassname="active"
              >
                <div className="d-flex textoMenuLateral">
                  <img
                    src={FaCertificados}
                    className="iconosAtencion"
                    alt="..."
                  />{" "}
                  <span>Certificados</span>
                </div>
              </NavLink>
              <div className="separadorItem" />
              <a
                className="navigate-button"
                activeclassname="active"
                onClick={handleRedirect}
              >
                <div className="d-flex textoMenuLateral">
                  <img
                    src={HospitalBritanico}
                    className="iconosAtencion"
                    alt="..."
                  />{" "}
                  <span>Hospital Británico</span>
                </div>
              </a>

              <div className="separadorItem" />
              <NavLink
                className="navigate-button"
                to={"/atencion-al-paciente"}
                activeclassname="active"
              >
                <div className="d-flex textoMenuLateral">
                  <img src={FaAtencion} className="iconosAtencion" alt="..." />{" "}
                  <span>Atención al paciente</span>
                </div>
              </NavLink>
              <div className="separadorItem" />
              <NavLink
                className="navigate-button"
                to={"/ayuda"}
                activeclassname="active"
              >
                <div className="d-flex textoMenuLateral">
                  <img src={FaAyuda} className="iconosAtencion" alt="..." />{" "}
                  <span>Ayuda</span>
                </div>
              </NavLink>
              {user?.user?.data?.Customers?.pshb && (
                <>
                  <div className="separadorTitulo" style={{ width: "100%" }} />
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#83888D",
                      padding: "10px 0px 0px 20px",
                    }}
                  >
                    PLAN DE SALUD
                  </span>
                  <Row
                    style={{ marginLeft: 0 }}
                    onClick={(e) => {
                      if (
                        e.target.tagName.toLowerCase() === "svg" ||
                        e.target.tagName.toLowerCase() === "polyline"
                      ) {
                        e.stopPropagation();
                      }
                    }}
                  >
                    <NavLink
                      className="navigate-button perfil-bottom"
                      to={"/facturas"}
                      activeclassname="active"
                      style={{ width: "72%" }}
                      onClick={(e) => {
                        if (
                          e.target.tagName.toLowerCase() === "svg" ||
                          e.target.tagName.toLowerCase() === "polyline"
                        ) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      <Col md={10}>
                        <div className="d-flex textoMenuLateral">
                          <div>
                            <img
                              src={FaFacturacion}
                              className="iconosAtencion"
                              alt="..."
                            />{" "}
                            <span>Facturación</span>
                          </div>
                        </div>
                      </Col>
                    </NavLink>
                    <Col
                      style={{
                        margin: "auto",
                        marginLeft: "10px",
                      }}
                    >
                      {isActiveFacturacion ? (
                        <FiChevronUp
                          size={20}
                          onClick={() =>
                            setIsActiveFacturacion(!isActiveFacturacion)
                          }
                        />
                      ) : (
                        <FiChevronDown
                          size={20}
                          onClick={() =>
                            setIsActiveFacturacion(!isActiveFacturacion)
                          }
                        />
                      )}
                    </Col>
                  </Row>
                  {isActiveFacturacion ? (
                    <>
                      <NavLink
                        className="d-flex navigate-button textoMenuLateral"
                        style={{
                          fontSize: "20px",
                          width: "100%",
                        }}
                        to={"/debito-automatico"}
                        activeclassname="active"
                      >
                        <div className="d-flex textoMenuLateral">
                          <span style={{ paddingLeft: "30px" }}>
                            En tarjeta de crédito
                          </span>
                        </div>
                      </NavLink>
                      <NavLink
                        className="d-flex navigate-button textoMenuLateral"
                        style={{
                          fontSize: "20px",
                          width: "100%",
                        }}
                        to={"/debito-cuenta"}
                        activeclassname="active"
                      >
                        <div className="d-flex textoMenuLateral">
                          <span style={{ paddingLeft: "30px" }}>
                            En cuenta bancaria
                          </span>
                        </div>
                      </NavLink>
                      <NavLink
                        className="d-flex navigate-button textoMenuLateral"
                        style={{
                          fontSize: "20px",
                          width: "100%",
                        }}
                        to={"/historial-debito"}
                        activeclassname="active"
                      >
                        <div className="d-flex textoMenuLateral">
                          <span style={{ paddingLeft: "30px" }}>Historial</span>
                        </div>
                      </NavLink>
                    </>
                  ) : null}
                  <div className="separadorItem" />
                  <div
                    className="navigate-button"
                    activeclassname="active"
                    onClick={stopPropagation}
                  >
                    <div>
                      <div className="d-flex textoMenuLateral">
                        <div>
                          <img
                            src={FaReintegros}
                            className="iconosAtencion"
                            alt="..."
                          />{" "}
                          <span>Reintegros</span>
                        </div>
                        <div
                          style={{ marginLeft: "auto", marginRight: "11px" }}
                        >
                          {isActiveReintegro ? (
                            <FiChevronUp
                              size={20}
                              onClick={() =>
                                setIsActiveReintegro(!isActiveReintegro)
                              }
                            />
                          ) : (
                            <FiChevronDown
                              size={20}
                              onClick={() =>
                                setIsActiveReintegro(!isActiveReintegro)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isActiveReintegro ? (
                    <>
                      <NavLink
                        className="d-flex navigate-button textoMenuLateral"
                        style={{
                          fontSize: "20px",
                          width: "100%",
                        }}
                        to={"/reintegro-estado"}
                        activeclassname="active"
                      >
                        <span style={{ paddingLeft: "30px" }}>Estado</span>
                      </NavLink>
                      <NavLink
                        className="d-flex navigate-button textoMenuLateral"
                        style={{
                          fontSize: "20px",
                          width: "100%",
                        }}
                        to={"/reintegro-solicitud"}
                        activeclassname="active"
                      >
                        <span style={{ paddingLeft: "30px" }}>Solicitud</span>
                      </NavLink>
                    </>
                  ) : null}
                  <div className="separadorItem" />
                  <NavLink
                    className="navigate-button"
                    to="/credencial-digital"
                    activeclassname="active"
                  >
                    <div className="d-flex textoMenuLateral">
                      <img
                        src={FaCredencial}
                        className="iconosAtencion"
                        alt="..."
                        // style={{ size: "80px" }}
                      />{" "}
                      <span>Credencial</span>
                    </div>
                  </NavLink>
                </>
              )}

              <div className="separadorTitulo" style={{ width: "100%" }} />
              <NavLink
                className="navigate-button"
                to={"/cerrar-sesion"}
                activeclassname="active"
              >
                <div className="d-flex textoMenuLateral">
                  <img src={FaLogOut} className="iconosAtencion" alt="..." />{" "}
                  <span>Cerrar sesión</span>
                </div>
              </NavLink>
            </div>
          </div>
        </aside>
      </div>
    </Box>
  );
  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <i
            class="bi bi-list"
            onClick={toggleDrawer(anchor, true)}
            style={{ fontSize: "30px", color: "#0062C1" }}
          ></i>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default DrawerHB;
